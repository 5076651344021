.App {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.drop {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  margin-top: 15px;
  background-color: #ECF3FF;
}