.sigContainer {
  width: 80%;
  height: 80%;
  margin: 0 auto;
  background-color: #fff;
}

.signPad {
  width: 100%;
  height: 100%;
}